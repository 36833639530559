import { combineReducers } from 'redux';
import localesReducer from './i18n'

const baseURLs = {
    urlsSetted: false,
    vtcoList: null,
    version: "",
    build: "",
    login: "",
    password: "",
    selectedVtcoProvider: null,
    vtcoTokenBody: "",
    vtcoKeysInfo: null,
    vtcoSelectedKey: null,
    currentUtcDateTime: null,
    publicKeyTextual: null,
    vtcoPin: "",
    clientId: "",
    selectedToken: null,
    tokenPin: "",
    details: null,
    json: null,
    cihsmApi: "",
    tokenId: "",
    show2fInput: false,
    twoFactor: "",
    numberOfPinInput: 3,
    usedTries: 1,
    ownerName: "",
    isSignOperation: false
}

const base = (state = baseURLs, { type, payload }) => {

    switch (type) {
        case 'SET_URLS': {
            return {
                ...state,
                urlsSetted: true,
                selectedVtcoProvider: payload.vtcoURLsList[0].url || null,
                vtcoList: payload.vtcoURLsList || null,
                clientId: payload.clientId || "cihsmVtcoServiceClient",
                cihsmApi: payload.cihsmApi
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_IS_LOADING': {
            return {
                ...state,
                isLoading: payload
            }
        }

        case 'SET_LOGIN': {
            return {
                ...state,
                login: payload
            }
        }

        case 'SET_PASSWORD': {
            return {
                ...state,
                password: payload
            }
        }

        case 'SET_SELECTED_VTCO_PROVIDER': {
            return {
                ...state,
                selectedVtcoProvider: payload
            }
        }

        case 'SET_PROTECTED_PARAMS': {
            return {
                ...state,
                currentUtcDateTime: payload.currentUtcDateTime,
                publicKeyTextual: payload.publicKeyTextual
            }
        }

        case 'SET_VTCO_KEYS_INFO': {
            return {
                ...state,
                vtcoKeysInfo: payload
            }
        }

        case 'SET_VTCO_SELECTED_KEY': {
            return {
                ...state,
                vtcoSelectedKey: payload
            }
        }

        case 'SET_VTCO_TOKEN_BODY': {
            return {
                ...state,
                vtcoTokenBody: payload
            }
        }

        case 'SET_SELECTED_TOKEN': {
            return {
                ...state,
                selectedToken: payload
            }
        }

        case 'SET_TOKEN_PIN': {
            return {
                ...state,
                tokenPin: payload
            }
        }

        case 'SET_DETAILS': {
            return {
                ...state,
                details: payload
            }
        }

        case 'SET_JSON': {
            return {
                ...state,
                json: payload
            }
        }

        case 'SET_CIHSM_API': {
            return {
                ...state,
                cihsmApi: payload
            }
        }

        case 'SET_TOKEN_ID': {
            return {
                ...state,
                tokenId: payload
            }
        }

        case 'SET_SHOW_2F_INPUT': {
            return {
                ...state,
                show2fInput: payload
            }
        }

        case 'SET_2F_INPUT': {
            return {
                ...state,
                twoFactor: payload
            }
        }

        case 'SET_NUMBER_OF_PIN_INPUT': {
            return {
                ...state,
                numberOfPinInput: payload
            }
        }

        case 'SET_USED_TRIES': {
            return {
                ...state,
                usedTries: payload
            }
        }

        case 'SET_OWNER_NAME': {
            return {
                ...state,
                ownerName: payload
            }
        }

        case 'SET_IS_SIGN_OPERATION': {
            return {
                ...state,
                isSignOperation: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    localesReducer: localesReducer
});



var setHeders, token = ""

// eslint-disable-next-line
setHeders = function(contentType, useToken) {
  var header;

  switch (contentType) {
  case "json":
      if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
      } else {
          if (useToken !== null && useToken !== undefined) {
            header = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${useToken}`
            }
          } else {
            header = {
              'Content-Type': 'application/json'
            }
          }
      }
    break;

  case "blob":
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${token}`
          }
      } else {
          if (useToken !== null && useToken !== undefined) {
            header = {
              'Content-Type': 'application/octet-stream',
              'Authorization': `Bearer ${useToken}`
            }
          } else {
            header = {
              'Content-Type': 'application/octet-stream'
            }
          }
      }
    break;

  case "text":
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`
          }
      } else {
        if (useToken !== null && useToken !== undefined) {
          header = {
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${useToken}`
          }
        } else {
          header = {
            'Content-Type': 'text/plain'
          }
        }
      }
    break;

  default:
    if (token !== undefined && token.length > 0) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
      } else {
        if (useToken !== null && useToken !== undefined) {
          header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${useToken}`
          }
        } else {
          header = {
            'Content-Type': 'application/json'
          }
        }
      }
    break;
  }
  return header
}

export const getURLs = () => (dispatch) => {

    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {

    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const vtcoLogin = (url, data) => (dispatch) => {
    var header = setHeders("json")

    return fetch(url + "/token.auth", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()     
    });
}

export const getVtcoTokenList = (url, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/token.list", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const getVtcoProtectedParams = (url, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/protectedParameters/settings", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        return response.json()     
    });
}

export const getOperationDetails = (url, data) => (dispatch) => {
    var header = setHeders("json")

    return fetch(url + "/operation-details", {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()     
    });
}

export const commit = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/commit", {
        method: 'PUT',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }).then(response => {
          if(response.ok) {
            return true
          } else {
            return response.json()
          }
           
    });
}

export const commit2f = (url, data, token) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/commit.2f", {
        method: 'PUT',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }).then(response => {
          if(response.ok) {
            return response.json()
          } else {
            return response.json()
          }
           
    });
}

export const rejectRequest = (url, data) => (dispatch) => {
    var header = setHeders("json")

    return fetch(url + "/reject", {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
          if(response.ok) {
            return true
          } else {
            return response.json()
          }
           
    });
}

export const getProtectedParams = (url) => (dispatch) => {
    // var header = setHeders("json")

    return fetch(url + "/protectedParameters/settings", {
        method: 'GET',
        dataType: "json",
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        return response.json()     
    });
}
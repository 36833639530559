import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import i18next from 'i18next'

import Cookies from 'universal-cookie';

import { setLogin, setPassword, setSelectedVtcoProvider, setVtcoTokenBody, setVtcoSelectedKey, setVtcoKeysInfo, setProtectedParams, setSelectedToken, setTokenPin,
		setDetails, setJson, setCihsmApi, setTokenId, setShow2fInput, set2fInput, setNumberOfPinInput, setUsedTries, setOwnerName } from '../../actions/localStates'
import { vtcoLogin, getVtcoTokenList, getVtcoProtectedParams, getOperationDetails, commit, rejectRequest, commit2f } from '../../actions/api'
import { cipher } from '../../actions/stringEncodersFuncs'

const cookies = new Cookies();

class Body extends Component {
	constructor(props) {
      super(props);
      this.state = {
          showPassword: false,
          isError: false,
          numberOfPinInput: 0,
          needToReEnterPin: false,
          showTokenList: false
      }

      this.handleLoginChange = this.handleLoginChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);

      this.handlePinChange = this.handlePinChange.bind(this);
      this.handle2fChange = this.handle2fChange.bind(this);

      this.handlePwdKeyUp = this.keyUpHandler.bind(this, 'PwdInput');

      this.onProviderChanged = this.onProviderChanged.bind(this);
      // this.onTokenChanged = this.onTokenChanged.bind(this);
      // this.enterKeyUpHandler = this.enterKeyUpHandler.bind(this, 'enter');

      this.getVtcoKeys = this.getVtcoKeys.bind(this);

      this.submitRequest = this.submitRequest.bind(this);
      this.cancel = this.cancel.bind(this);

      this.submitRequest2f = this.submitRequest2f.bind(this);

      this.setShowTokenList = this.setShowTokenList.bind(this);
  	}

  	setShowTokenList () {
  		this.setState({showTokenList: !this.state.showTokenList})
  	}

  	keyUpHandler(refName, e) {
      if (e.keyCode === 13) {
      	if (this.props.base.selectedToken !== null && this.props.base.vtcoKeysInfo !== undefined) {
      		document.getElementById("submit").click();
        	document.activeElement.blur()
      	} else {
      		document.getElementById("submit-vtco").click();
        	document.activeElement.blur()
      	}
        
      }
  	}

  	cancel () {
  		var data = {
		    clientId : this.props.base.json["clientId"],
		    operationId : this.props.base.json["operationId"]
		}

  		this.props.actions.rejectRequest(this.props.base.cihsmApi, data)
  		.then((response) => {
  			if (response.code !== undefined) {
				console.log(response);
				alert(response.message)
			} else {
				this.props.actions.setLogin("");
		  		this.props.actions.setPassword("");
		  		this.props.actions.setSelectedVtcoProvider(null);
		  		this.props.actions.setVtcoTokenBody("");
		  		this.props.actions.setTokenId("");
		  		this.props.actions.setVtcoSelectedKey(null);
		  		this.props.actions.setVtcoKeysInfo(null);
		  		// this.props.actions.setProtectedParams(null);
		  		this.props.actions.setSelectedToken(null);
		  		this.props.actions.setTokenPin("");
				this.props.actions.setDetails(null);
				this.props.actions.setJson(null);
				window.open("about:blank", "_self");
				window.close();
			}
  		})
  	}

	componentDidMount() {
		document.addEventListener('keyup', this.enterKeyUpHandler);
	}

	setShow2f () {
		this.setState({show2f: this.state.show2f ? false : true})
	}

	setShowPin () {
	    this.setState({showPin: this.state.showPin ? false : true})
	}

	hover2f () {
	    this.setState({show2f: true})
	}

	out2f () {
	    this.setState({show2f: false})
	}

	hoverPin () {
	    this.setState({showPin: true})
	}

	outPin () {
	    this.setState({showPin: false})
	}

	setShowPassword() {
	    this.setState({showPassword: this.state.showPassword ? false : true})
	}

	hover() {
	    this.setState({showPassword: true})
	}

	out() {
	    this.setState({showPassword: false})
	}

	handleLoginChange (e) {
		this.props.actions.setLogin(e.target.value)
	}

	handlePasswordChange (e) {
		this.props.actions.setPassword(e.target.value)
	}

	handlePinChange (e) {
		this.props.actions.setTokenPin(e.target.value)
	}

	handle2fChange (e) {
		this.props.actions.set2fInput(e.target.value)
	}

	onProviderChanged (e) {
		// eslint-disable-next-line
		this.props.base.vtcoList.map((provider, index) =>
        {
        	if (provider.url === e.target.value) {
        		this.props.actions.setCihsmApi(provider.iitSignerUrl)
        		// eslint-disable-next-line
        		return
        	}
        })
		this.props.actions.setSelectedVtcoProvider(e.target.value)
	}

	onTokenChanged (value) {
		var tokenUid
		// eslint-disable-next-line
		this.props.base.vtcoKeysInfo.map((key) => {
			if (key["uri"] === value) {
				tokenUid = value.substring(value.lastIndexOf('.') + 1, value.length)
				this.props.actions.setOwnerName(tokenUid + ", " + key.signatureKey.subjectCommonName)
			}
		})
		
		this.props.actions.setSelectedToken(value)
		this.setState({showTokenList: false})
	}

	submitRequest2f () {
		
		var jsonObject = {
        	"signatureFormat": this.props.base.details.commitRequestToEncrypt["signatureFormat"],
		    "pin": this.props.base.tokenPin,
		    "embedSignerCertificate": this.props.base.details.commitRequestToEncrypt["embedSignerCertificate"],
		    "embedContentTs": this.props.base.details.commitRequestToEncrypt["embedContentTs"],
		    "hashAlgorithm": this.props.base.details.commitRequestToEncrypt["hashAlgorithm"],
		    "hashesToSign": this.props.base.details.commitRequestToEncrypt["hashesToSign"]
	    };

	    var cipherResult = cipher(jsonObject, this.props.base.publicKeyTextual, this.props.base.currentUtcDateTime);

	    var data = {
	    	"clientId" : this.props.base.json["clientId"],
		    "operationId" : this.props.base.json["operationId"],
		    "ciHsmTokenUri": this.props.base.selectedToken,
		    "encryptedData": cipherResult.encryptedData,
		    "secretKey": cipherResult.secretKey
	    }

	    this.props.actions.commit2f(this.props.base.cihsmApi, data, this.props.base.tokenId)
	    .then((response) => {
	    	console.log(response)

	    	if ((response.codeTtl === 0 && response.remainingCodeVerifyAttemptsNumber === 0) || (response.codeTtl === -2 || response.codeTtl === -1)) {
	    		this.submitRequest();
	    		return;
	    	} else {
	    		if (this.props.base.show2fInput) {
	    			this.props.actions.setNumberOfPinInput(response.remainingCodeVerifyAttemptsNumber)
	    			this.submitRequest();
	    		} else {
	    			this.props.actions.setShow2fInput(true);
	    			document.getElementById("twoFactor").focus();
	    		}
	    		
	    	}    	
	    })
	}

	submitRequest () {
		var _this = this;
		var jsonObject = {
        	"signatureFormat": this.props.base.details.commitRequestToEncrypt["signatureFormat"],
		    "pin": this.props.base.tokenPin,
		    "embedSignerCertificate": this.props.base.details.commitRequestToEncrypt["embedSignerCertificate"],
		    "embedContentTs": this.props.base.details.commitRequestToEncrypt["embedContentTs"],
		    "hashAlgorithm": this.props.base.details.commitRequestToEncrypt["hashAlgorithm"],
		    "hashesToSign": this.props.base.details.commitRequestToEncrypt["hashesToSign"]
	    };

	    var cipherResult = cipher(jsonObject, this.props.base.publicKeyTextual, this.props.base.currentUtcDateTime);

	    var data = {
	    	"clientId" : this.props.base.json["clientId"],
		    "operationId" : this.props.base.json["operationId"],
		    "ciHsmTokenUri": this.props.base.selectedToken,
		    "encryptedData": cipherResult.encryptedData,
		    "secretKey": cipherResult.secretKey,
		    "twoFactorCode": this.props.base.twoFactor
	    }

	    this.props.actions.commit(this.props.base.cihsmApi, data, this.props.base.tokenId)
	    .then((response) => {
	    	console.log(response)
	    	if (response.code === "AUTHCOMMON-14") {
        		var currentCount = _this.state.numberOfPinInput
        		var used = this.props.base.usedTries

        		if (currentCount === 0) {
        			currentCount = _this.props.base.numberOfPinInput - 1;
        		} else {
        			currentCount = currentCount - 1;

        			used += 1;

        			this.props.actions.setUsedTries(used)
        		}

        		if (this.props.base.numberOfPinInput === this.props.base.usedTries) {
    				this.cancel()
    			}

        		_this.setState({numberOfPinInput: currentCount});
				_this.setState({needToReEnterPin: true});
				return;
        	}

	    	if (response === true) {
	    		if (!this.props.base.isSignOperation) {
		    		cookies.set(this.props.base.json["clientId"], this.props.base.selectedToken, { path: '/', expires: new Date(Date.now() + 600000) });
					cookies.set(this.props.base.json["clientId"] + "_token", this.props.base.tokenId, { path: '/', expires: new Date(Date.now() + 600000) });
				}
				this.props.actions.setShow2fInput(false);
				this.setState({numberOfPinInput: 0})
				this.props.actions.setUsedTries(1)
	    		// alert(i18next.t("requestSuccess"))
				window.open("about:blank", "_self");
				window.close();
	    	} else {
	    		alert(response.message)
	    		window.close();
	    	}
	    	
	    })
	}

	getVtcoKeys () {
		var sortedArray = [], resultObject;
		// this.props.actions.setUseUccConnection(false)

		// this.setState({isLoading: true})

		var data = {
			userName : this.props.base.login,
			password : this.props.base.password,
			clientId : this.props.base.clientId
		}

		this.props.actions.vtcoLogin(this.props.base.selectedVtcoProvider, data)
		.then((response) => {
			if (response.code !== undefined) {
				this.setState({isLoading: false})
				console.log(response);
				alert(response.message)
			} else {
				// this.props.actions.setTwoFactorEnable(response.isTwoFactorEnable)
				this.props.actions.setVtcoTokenBody(response.tokenBody)
				this.props.actions.setTokenId(response.tokenBody)

				this.props.actions.getVtcoTokenList(this.props.base.selectedVtcoProvider, response.tokenBody)
				.then((response) => {
						if (response.code !== undefined) {
						this.setState({isLoading: false})
						console.log(response);
						alert(response.message)
					} else {
						function search(nameKey, myArray){
			                for (var i = 0; i < myArray.length; i += 1) {
			                    if (myArray[i].uri === nameKey) {
			                        return myArray[i];
			                    }
			                }
			            }

			            // eslint-disable-next-line
						response.map((key) => {
	                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === false) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === false)) {
	                          sortedArray.push(key)
	                        }
	                    })

						// eslint-disable-next-line
	                    response.map((key) => {
	                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === true) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === true)) {
	                          	resultObject = search(key.uri, sortedArray);
		                        if (resultObject === undefined) {
		                            sortedArray.push(key)
		                        }
	                        }
	                    })

						this.props.actions.setVtcoSelectedKey(sortedArray[0])
						this.props.actions.setVtcoKeysInfo(sortedArray)
						this.props.actions.setSelectedToken(sortedArray[0].uri)

						var tokenUid = sortedArray[0].uri.substring(sortedArray[0].uri.lastIndexOf('.') + 1, sortedArray[0].uri.length)

						this.props.actions.setOwnerName(tokenUid + ", " + response[0].signatureKey.subjectCommonName)

						this.props.actions.getVtcoProtectedParams(this.props.base.selectedVtcoProvider, this.props.base.vtcoTokenBody)
						.then((response) => {
							if (response.code !== undefined) {
								this.setState({isLoading: false})
								console.log(response);
								alert(response.message)
							} else {
								this.props.actions.setProtectedParams(response)

								if (!this.props.base.isSignOperation) {
									cookies.set(this.props.base.json["clientId"], this.props.base.selectedToken, { path: '/', expires: new Date(Date.now() + 600000) });
									cookies.set(this.props.base.json["clientId"] + "_token", this.props.base.tokenId, { path: '/', expires: new Date(Date.now() + 600000) });
								} else {
									var json = this.props.base.json
									json["action"] = "sign"
									this.props.actions.setJson(json);
								}

								this.setState({isLoading: false})
								this.props.actions.setUsedTries(1)
								document.getElementById("pinInput").focus();
								console.log(response);
							}
						})
					}
				})
			}
		})
    }

	render() {
		let isEnabled = this.props.base.tokenPin.length > 0 ? true : false

		if (this.props.base.show2fInput) {
			if (this.props.base.twoFactor.length > 0) {
				isEnabled = true
			} else {
				isEnabled = false
			}
		}

		let showTokenListOpen = 'dropdown-menu'
		if (this.state.showTokenList) {
			showTokenListOpen = 'dropdown-menu show'
		}
		return (
				<div className="container aligned-form" style={{maxWidth: "500px", minWidth: "350px"}}>
					<div>
						{
							this.props.base.json !== null && this.props.base.json["action"] === "auth"
							? <div>
								<h4>{i18next.t("readKeyLabel")}</h4>
								<label style={{fontWeight: "600", marginBottom: "0px"}}>{i18next.t("sender")}</label>
								<label className="col-12" style={{paddingLeft: "0px", paddingRight: "0px", fontSize: "14px"}}>{this.props.base.details !== null ? this.props.base.details.originatorDescription : ""}</label>
							</div>
							: <div>
								<h4>{i18next.t("submitSignLabel")}</h4>
								<label style={{fontWeight: "600", marginBottom: "0px"}}>{i18next.t("sender")}</label>
								<label className="col-12" style={{paddingLeft: "0px", paddingRight: "0px", fontSize: "14px"}}>{this.props.base.details !== null ? this.props.base.details.originatorDescription : ""}</label>
								<label style={{fontWeight: "600", marginBottom: "0px"}}>{i18next.t("dataDescription")}</label>
								{
									this.props.base.details !== null
									? 	<div>
											{
			                                  this.props.base.details.operationDescriptions.map((description, index) =>
			                                    <label key={index} style={{fontSize: "14px"}}>{description}</label>)
			                                }   
			                            </div>
									: null
								}
							</div>
						}
					</div>
					<div className="card" style={{marginTop: "8px", marginBottom: "8px"}}>
	                    <div className="card-body">
	                    	{
	                    		this.props.base.selectedToken !== null && this.props.base.vtcoKeysInfo !== undefined
	                    		? <div>
	                    			{
	                    				this.props.base.vtcoKeysInfo !== null && this.props.base.vtcoKeysInfo !== undefined && this.props.base.vtcoKeysInfo.length > 1
										? <div className="form-group row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
		                    				<label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label" style={{paddingleft: "0px"}}>{i18next.t("tokenLabel")}</label>
		                    					<div className="dropdown col-lg-9 col-md-12 col-xs-12 col-sm-12">
												    <button className="btn token-dropdown col-lg-12 col-md-12 col-xs-12 col-sm-12" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={this.setShowTokenList}>
												    	<label className="text-overflow-label" style={{marginBottom: "0px"}}>{this.props.base.ownerName}</label>
												    </button>
												    <div className={showTokenListOpen} style={{marginLeft: "14px"}}>
												    	{
						                                  	this.props.base.vtcoKeysInfo.map((provider, index) =>
						                                    <div className="dropdown-item cursor-pointer" key={index} onClick={this.onTokenChanged.bind(this, provider.uri)}>
						                                    	<p style={{marginBottom: "0px"}}>
						                                    		{provider.uri}
						                                    	</p>
						                                    	<p style={{marginBottom: "0px", fontSize: "14px"}}>
						                                    		{provider.signatureKey.subjectCommonName}
						                                    	</p>
						                                    	<p style={{marginBottom: "0px", fontSize: "14px"}}>
						                                    		{provider.signatureKey.issuerCommonName}
						                                    	</p>
						                                    	<p style={{marginBottom: "8px", fontSize: "14px"}}>
						                                    		{provider.signatureKey.algorithmInfo}
						                                    	</p>
						                                    </div>)
						                                }

													</div>

												</div>
		                    			</div>
										: null
	                    			}
	                    			
	                    			{/*{
										this.props.base.vtcoKeysInfo !== null && this.props.base.vtcoKeysInfo !== undefined && this.props.base.vtcoKeysInfo.length > 1
										? <div className="form-group row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
												<label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label" style={{paddingleft: "0px"}}>{i18next.t("tokenLabel")}</label>
												<div className="col-lg-9 col-md-12 col-xs-12 col-sm-12">
													<select type="select" disabled={this.props.base.vtcoKeysInfo.length > 1 ? false : true} className="form-control" style={{textAlignLast: "center", marginBottom: "10px"}} defaultValue={this.props.base.selectedToken} onChange={this.onTokenChanged}>
						                                {
						                                  this.props.base.vtcoKeysInfo.map((provider, index) =>
						                                    <option key={index} value={provider.uri}>{provider.title}</option>)
						                                }
						                            </select>
						                        </div>
				                            </div>
			                            : null
										
									}*/}
									{
										this.props.base.selectedToken !== null && this.props.base.selectedToken !== undefined && this.props.base.json !== null && this.props.base.json.action === "sign"
										? <div className="form-group row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
											<label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("tokenLabel")}</label>
											<label className="col-lg-9 col-md-12 col-xs-12 col-sm-12">{this.props.base.selectedToken}</label>
										</div>
										: null
									}
									{
										this.props.base.show2fInput
										? <div className="form-group row">
				                            <label className="col-lg-5 col-md-12 col-xs-12 col-sm-12 col-form-label" style={{paddingRight: "0px"}}>{i18next.t("oneTimeCodeTitle")}</label>
				                              <div className="col-lg-7 col-md-12 col-xs-12 col-sm-12">
				                              <div className="input-group">
				                                  <input className="form-control" id="twoFactor" type={this.state.show2f ? "input" : "password"} value={this.props.base.twoFactor} onChange={this.handle2fChange} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
				                                      <div className="input-group-append">
				                                          <div className="input-group-text" onClick={this.setShow2f.bind(this)} onMouseOver={this.hover2f.bind(this)} onMouseOut={this.out2f.bind(this)}><i className="fa fa-eye"></i></div>
				                                      </div>
				                                </div>
				                              </div>
				                              {
				                              	this.state.needToReEnterPin && this.state.numberOfPinInput > 0
				                              	? <label style={{paddingLeft: "15px", marginBottom: "0px", paddingTop: "10px"}}>{i18next.t("triesLeft")}{this.state.numberOfPinInput}</label>
				                              	: null
				                              }
				                        </div>
										: <div className="form-group row">
				                            <label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label" style={{paddingRight: "0px"}}>{i18next.t("pinLabel")}</label>
				                              <div className="col-lg-9 col-md-12 col-xs-12 col-sm-12">
				                              <div className="input-group">
				                                  <input className="form-control" id="pinInput" type={this.state.showPin ? "input" : "password"} value={this.props.base.tokenPin} onChange={this.handlePinChange} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
				                                      <div className="input-group-append">
				                                          <div className="input-group-text" onClick={this.setShowPin.bind(this)} onMouseOver={this.hoverPin.bind(this)} onMouseOut={this.outPin.bind(this)}><i className="fa fa-eye"></i></div>
				                                      </div>
				                                </div>
				                              </div>
				                        </div>
									}
									
	                    		 </div>
	                    		: <div>
			                    	{
										this.props.base.vtcoList !== null && this.props.base.vtcoList !== undefined && this.props.base.vtcoList.length > 1
										? <select type="select" disabled={this.props.base.vtcoList.length > 1 ? false : true} className="form-control" style={{textAlignLast: "center", marginBottom: "10px"}} defaultValue={this.props.base.selectedVtcoProvider} onChange={this.onProviderChanged}>
			                                {
			                                  this.props.base.vtcoList.map((provider, index) =>
			                                    <option key={index} value={provider.url}>{provider.vtcoTitle}</option>)
			                                }
			                            </select>
			                            : null
										
									}
									
									<div className="form-group row">
		                                <label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("loginLabel")}</label>
		                                  <div className="col-lg-9 col-md-12 col-xs-12 col-sm-12">
		                                  <div className="input-group">
		                                    <input type="text" className="form-control" value={this.props.base.login} onChange={this.handleLoginChange} style={{"backgroundColor": "white"}} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
		                                  </div>
		                                </div>
		                            </div>
		                            <div className="form-group row">
			                            <label className="col-lg-3 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("PasswordLabel")}</label>
			                              <div className="col-lg-9 col-md-12 col-xs-12 col-sm-12">
			                              <div className="input-group">
			                                  <input className="form-control" type={this.state.showPassword ? "input" : "password"} value={this.props.base.password} onChange={this.handlePasswordChange} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
			                                      <div className="input-group-append">
			                                          <div className="input-group-text" onClick={this.setShowPassword.bind(this)} onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
			                                      </div>
			                                </div>
			                              </div>
			                        </div>
			                        <div className="row">
			                        	<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
					                        <button id="submit-vtco" className="btn btn-default col-lg-12 col-md-12 col-xs-12 col-sm-12" onClick={this.getVtcoKeys} style={{marginBottom: "10px"}}>{this.props.base.json !== null ? i18next.t(this.props.base.json["action"]) : "ok"}
				                                {
				                                  this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
				                                    : null
				                                }
				                            </button>
				                        </div>
			                        </div>
		                        </div>
	                    	}
	                    	
	                    </div>
	                </div>
	                <div className="row" style={{paddingTop: "10px"}}>
	                	{
	                		<div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
		                		{
		                			<button id="submit" disabled={!isEnabled} className="btn btn-default col-lg-12 col-md-12 col-xs-12 col-sm-12" onClick={this.submitRequest2f} style={{marginBottom: "10px"}}>{i18next.t("submitAction")}
		                                {
		                                  this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
		                                    : null
		                                }
		                            </button>
		                		}
	                        </div>
	                	}
	                	
	                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-xs-12 col-sm-12" onClick={this.cancel} style={{marginBottom: "10px"}}>{i18next.t("cancelLabel")}
                                {
                                  this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                    : null
                                }
                            </button>
                        </div>
                    </div>
				</div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setLogin, setPassword, setSelectedVtcoProvider,
    	vtcoLogin, setVtcoTokenBody, getVtcoTokenList,
    	getVtcoProtectedParams, setVtcoSelectedKey,
    	setVtcoKeysInfo, setProtectedParams,
    	setSelectedToken, setTokenPin, getOperationDetails,
    	commit, cipher, setDetails, setJson, setCihsmApi,
    	rejectRequest, setTokenId, commit2f, setShow2fInput, set2fInput,
    	setNumberOfPinInput, setUsedTries, setOwnerName
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);